import React from 'react'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { buildCalendarUrl } from '../../features/calendar/CalendarService'
import dayjs from 'dayjs'
import './HeaderMenuNavigation.scss'
import classNames from 'classnames'
import { LOGO } from '../../assets/logos'

function HeaderMenuApplications() {
  const { t } = useTranslation('layout')

  return (
    <div className="menu-navigation-container">
      <h3 className="main-title">{t('Applications')}</h3>
      <div className="menu-navigation-content">
        <a
          className={classNames({
            'menu-navigation-element': true,
            'menu-navigation-element--active-meeting':
              location.pathname.includes('meeting'),
          })}
          href="/upcoming-meetings"
        >
          <span className="menu-navigation-logo-container">
            <LOGO.logo_auxameetings/>
          </span>
          Meetings
        </a>
        <a
          className={classNames({
            'menu-navigation-element': true,
            'menu-navigation-element--active-calendar':
              location.pathname.includes('calendar'),
          })}
          href={buildCalendarUrl(dayjs().toString(), 'week')}
        >
          <span className="menu-navigation-logo-container">
            <LOGO.logo_auxaplanning/>
          </span>
          Planning
        </a>
        <a
          className={classNames({
            'menu-navigation-element': true,
            'menu-navigation-element--active-messages':
              location.pathname.includes('messages'),
          })}
          href="/messages"
        >
          <span className="menu-navigation-logo-container">
            <LOGO.logo_auxateam/>
          </span>
          Team
        </a>
        <a
          className={classNames({
            'menu-navigation-element': true,
            'menu-navigation-element--active-drives':
              location.pathname.includes('drives'),
          })}
          href="/drives/home"
        >
          <span className="menu-navigation-logo-container">
            <LOGO.logo_auxadrive/>
          </span>
          Drive
        </a>
        <Tooltip title={t('Coming soon', { ns: 'common' })}>
          <div className="menu-navigation-element">
            <span className="menu-navigation-logo-container">
              <LOGO.logo_auxatransfer/>
            </span>
            Transfer
          </div>
        </Tooltip>
      </div>
    </div>
  )
}

export default HeaderMenuApplications
