import { createSelector } from '@reduxjs/toolkit'
import { Collapse, Input, Space } from 'antd'
import classNames from 'classnames'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getChatDetails } from '../../../features/room/RoomChat/ChatLibrary'
import { AppDispatch, RootState } from '../../../store'
import CreateChat from '../CreateChat/CreateChat'
import '../CreateChat/ModifyChatPeers.scss'
import { Squad } from '../Squad/squadListSlice'
import './ChatList.scss'
import { Chat as ChatInterface, setCurrentChatId } from './chatsSlice'
import DraftMessage from './DraftMessage'
import LastMessage from './LastMessage'

interface Props {
  inCall?: boolean
  currentSquadId: string
  setOpenResponsiveDrawerMenu?: (open: boolean) => void
}

export default function ChatList({
  inCall,
  currentSquadId,
  setOpenResponsiveDrawerMenu,
}: Props) {
  const selectSquad = (state: RootState) => state.chatSquads.squads[currentSquadId]
  const selectChats = (state: RootState) => state.chats.chats[currentSquadId]
  const selectChatsDetails = (state: RootState) => state.chats.chatsDetails
  const { t } = useTranslation('chat')
  const [q, setQ] = useState('')
  const qNormalized = q
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLocaleLowerCase()

  const chatsSelector = createSelector(
    [selectSquad, selectChats, selectChatsDetails, (_, qNormalized) => qNormalized],
    (squad, chats, chatsDetails, qNormalized) => {
      const p2pChats: React.ReactElement[] = []
      const groupChats: React.ReactElement[] = []
      const archivedChats: React.ReactElement[] = []

      const lastMessageChatSort = (a: ChatInterface, b: ChatInterface) => {
        if (a.everybody && b.everybody) {
          //keep geenral chat at the top of the list
          return -1
        } else if (!a.everybody && b.everybody) {
          return 1
        }
        if (!chatsDetails[a.id] || !chatsDetails[b.id]) return 0
        return dayjs(
          chatsDetails[a.id].lastMessage
            ? chatsDetails[a.id].lastMessage?.createdAt
            : dayjs('1970-01-01'),
        ).isBefore(
          dayjs(
            chatsDetails[b.id].lastMessage
              ? chatsDetails[b.id].lastMessage?.createdAt
              : dayjs('1970-01-01'),
          ),
        )
          ? 1
          : -1
      }

      if (squad && chats) {
        let chatSorted
        if (chats[0].everybody) {
          chatSorted = [
            chats[0],
            ...chats.slice(1).sort((a, b) => lastMessageChatSort(a, b)),
          ]
        } else {
          chatSorted = [...chats.slice(0).sort((a, b) => lastMessageChatSort(a, b))]
        }
        for (const c of chatSorted || []) {
          const { name, icon, backgroundColor } = getChatDetails(c)

          if (qNormalized) {
            const nameNormalized = name
              .normalize('NFD')
              .replace(/\p{Diacritic}/gu, '')
              .toLocaleLowerCase()
            if (!nameNormalized.includes(qNormalized)) {
              continue
            }
          }

          if (c.archived) {
            archivedChats.push(
              <ChatListLine
                key={c.id}
                squad={squad}
                chat={c}
                setOpenResponsiveDrawerMenu={setOpenResponsiveDrawerMenu}
                name={name}
                icon={icon}
                backgroundColor={backgroundColor}
              />,
            )
          } else {
            if (c.everybody) {
              groupChats.push(
                <ChatListLine
                  key={c.id}
                  squad={squad}
                  chat={c}
                  setOpenResponsiveDrawerMenu={setOpenResponsiveDrawerMenu}
                  name={name}
                  icon={icon}
                  backgroundColor={backgroundColor}
                />,
              )
            } else if (c.peerToPeer) {
              p2pChats.push(
                <ChatListLine
                  key={c.id}
                  squad={squad}
                  chat={c}
                  setOpenResponsiveDrawerMenu={setOpenResponsiveDrawerMenu}
                  name={name}
                  icon={icon}
                  backgroundColor={backgroundColor}
                />,
              )
            } else {
              groupChats.push(
                <ChatListLine
                  key={c.id}
                  squad={squad}
                  chat={c}
                  setOpenResponsiveDrawerMenu={setOpenResponsiveDrawerMenu}
                  name={name}
                  icon={icon}
                  backgroundColor={backgroundColor}
                />,
              )
            }
          }
        }
      }

      return { p2pChats, groupChats, archivedChats }
    },
  )

  const { p2pChats, groupChats, archivedChats } = useSelector((state) =>
    chatsSelector(state, qNormalized),
  )

  return (
    <>
      <CreateChat squadId={currentSquadId} />
      <Space.Compact className="mh-1em pb-1rem">
        <Input
          placeholder={t('Search')}
          allowClear
          onChange={(v) => setQ(v.target.value)}
        />
      </Space.Compact>
      <Scrollbars className="ChatListComponent" autoHide>
        <div>
          <div>
            {groupChats.length > 0 && (
              <Collapse
                className="chat_group"
                collapsible="header"
                defaultActiveKey={['1']}
                ghost
                items={[
                  {
                    key: '1',
                    label: (
                      <>
                        <div className="chat_group_title">
                          <div className="mr-05rem">{t('Discussion channels')}</div>
                        </div>
                      </>
                    ),
                    children: groupChats,
                  },
                ]}
              />
            )}

            <Collapse
              className="chat_group"
              defaultActiveKey={['1']}
              collapsible="header"
              ghost
              items={[
                {
                  key: '1',
                  label: (
                    <div className="chat_group_title">
                      <div className="mr-05rem">{t('Private messages')}</div>
                    </div>
                  ),
                  children: p2pChats,
                },
              ]}
            />

            {archivedChats.length > 0 && (
              <Collapse
                className="chat_group"
                collapsible="header"
                defaultActiveKey={['1']}
                ghost
                items={[
                  {
                    key: '2',
                    label: (
                      <div className="chat_group_title">
                        <div className="mr-05rem">{t('Archives')}</div>
                      </div>
                    ),
                    children: archivedChats,
                  },
                ]}
              />
            )}
          </div>
        </div>
      </Scrollbars>
    </>
  )
}

interface ChatLineProps {
  chat: ChatInterface
  squad: Squad
  setOpenResponsiveDrawerMenu?: (open: boolean) => void
  name: string
  icon: string
  backgroundColor: string
}

function ChatListLine({
  squad,
  chat,
  setOpenResponsiveDrawerMenu,
  name,
  icon,
  backgroundColor,
}: ChatLineProps) {
  const { t } = useTranslation('chat')
  const dispatch = useDispatch<AppDispatch>()
  const chatsDetails = useSelector((state: RootState) => state.chats.chatsDetails)
  const lastMessage = chatsDetails[chat.id]?.lastMessage
  const unreadMessagesCount = chatsDetails[chat.id]?.unreadMessagesCount
  const currentChatId = useSelector((state: RootState) => state.chats.currentChat?.id)
  const peersStatuses = useSelector(
    (state: RootState) => state.chatSquads.peersStatuses[squad.id],
  )
  const typingUsers = useSelector((state: RootState) => state.chats.typingUsers[chat.id])

  const messageInputDraft = useSelector(
    (state: RootState) => state.chats.messageInputDraft[chat.id],
  )

  function selectChat(chatId: string) {
    dispatch(setCurrentChatId(chatId))
    if (setOpenResponsiveDrawerMenu) {
      setOpenResponsiveDrawerMenu(false)
    }
  }

  return (
    <div
      key={chat.id}
      onClick={() => selectChat(chat.id)}
      className={classNames({
        chat_item: true,
        'chat_item--current': chat.id === currentChatId,
        'chat_item--unread': unreadMessagesCount > 0,
      })}
    >
      <div className="chat_item_avatar_container">
        <div className="chat_item_avatar" style={{ backgroundColor }}>
          {icon}
        </div>
        {chat.peerToPeer &&
          chat.peer &&
          peersStatuses &&
          peersStatuses[chat.peer.email] === 'ONLINE' && (
            <div className="chat_item_p2p_online" />
          )}
      </div>
      <div className="chat_item_details">
        <div className="chat_item_details_first">
          <div className="chat_item_title">
            <span>
              <span className="chat_item_name">{t(name, { ns: 'meetings' })}</span>
            </span>
          </div>
          <div className="chat_item_last_message_date">
            {lastMessage &&
              (dayjs().format('LL') === dayjs(lastMessage.createdAt).format('LL')
                ? dayjs(lastMessage.createdAt).format('HH:mm')
                : dayjs(lastMessage.createdAt).format('DD/MM'))}
          </div>
        </div>
        <div className="chat_item_details_second">
          {typingUsers && Object.values(typingUsers).length > 0 ? (
            <div className="chat_item_last_message">
              {Object.values(typingUsers).length == 1
                ? t('USER_TYPING', {
                    user: Object.values(typingUsers).map((user) => user.firstName).join(', '),
                  })
                : t('Several people are writing...')}
            </div>
          ) : messageInputDraft?.text || messageInputDraft?.files ? (
            <DraftMessage messageInputDraft={messageInputDraft} />
          ) : (
            <LastMessage
              lastMessage={lastMessage}
              unreadMessagesCount={unreadMessagesCount}
            />
          )}
        </div>
      </div>
    </div>
  )
}
