import React, { useEffect } from 'react'
import { Button, Form, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  idleUpdateDriveStatus,
  setDriveToDelete,
  setDriveToModify,
  setGroups,
  setParticipants,
  updateDrive,
} from '../../../features/Drives/redux/drivesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store'
import { useAttemptsListener } from 'dev-masters-react-kit'
import DriveForm from './DriveForm'
import { useToastContext } from '../../Toast/ToastContext'

function UpdateDriveModal() {
  const { t } = useTranslation(['drives'])

  const dispatch = useDispatch<AppDispatch>()
  const [form] = Form.useForm()
  const driveToModify = useSelector((state: RootState) => state.drive.driveToModify)

  const initDriveParticipants = driveToModify?.users || []
  const initDriveGroups = driveToModify?.groups || []

  const participants = useSelector(
    (state: RootState) => state.drive.driveModifyForm.participants,
  )
  const groups = useSelector((state: RootState) => state.drive.driveModifyForm.groups)
  const isAdmin = useSelector((state: RootState) => state.drive.driveModifyForm.isAdmin)

  const updateDriveStatus = useSelector(
    (state: RootState) => state.drive.updateDriveStatus,
  )
  const disabledParticipantsEmail: React.Key[] = [driveToModify?.creatorEmail || '']
  const { ToastOpen } = useToastContext()

  /**
   *
   */
  useEffect(() => {
    form.setFieldValue('name', driveToModify?.name)
    dispatch(setParticipants(initDriveParticipants))
    dispatch(setGroups(initDriveGroups))
  }, [driveToModify])

  useAttemptsListener([
    [
      updateDriveStatus,
      {
        success: () => {
          ToastOpen({
            message: t('Drive was successfully updated.'),
            type: 'success',
          })
          resetModal()
        },
        NO_ADMIN_RIGHTS: () => {
          ToastOpen({
            message: t('You do not have the necessary rights to update this drive.'),
            type: 'error',
          })
        },
        unknown_error: () => {
          ToastOpen({
            message: t('Error updating drive.'),
            type: 'error',
          })
        },
      },
      () => dispatch(idleUpdateDriveStatus()),
    ],
  ])

  /**
   *
   */
  function onDriveEdit() {
    if (driveToModify) {
      dispatch(
        updateDrive({
          id: driveToModify.id,
          name: form.getFieldsValue().name,
          users: participants.filter(
            (participant) => !disabledParticipantsEmail.includes(participant.email),
          ),
          groups: groups,
        }),
      )
    }
  }

  /**
   *
   */
  function resetModal() {
    dispatch(setParticipants([]))
    dispatch(setGroups([]))
    dispatch(setDriveToModify(undefined))
  }

  function deleteDrive() {
    dispatch(setDriveToDelete(driveToModify))
  }

  return (
    <Modal
      centered
      open={driveToModify !== undefined}
      title={
        driveToModify && !isAdmin
          ? t('View drive', { ns: 'drives' })
          : t('Modify drive', { ns: 'drives' })
      }
      onOk={form.submit}
      onCancel={resetModal}
      footer={[
        driveToModify && isAdmin && (
          <div className="delete-btn-containter">
            <Button
              type="primary"
              danger
              key="delete"
              onClick={deleteDrive}
              className="delete-btn"
            >
              {t('Delete drive', { ns: 'drives' })}
            </Button>
          </div>
        ),
        <Button key="back" onClick={resetModal}>
          {driveToModify && !isAdmin
            ? t('Close', { ns: 'common' })
            : t('Cancel', { ns: 'common' })}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onDriveEdit}
          style={driveToModify && !isAdmin ? { display: 'none' } : {}}
        >
          {t('Modify')}
        </Button>,
      ]}
      width={isAdmin ? '70em' : '50em'}
    >
      <div className="edit-drive-modal">
        <DriveForm driveForm={form} onDriveSubmit={onDriveEdit} newDrive={false} />
      </div>
    </Modal>
  )
}

export default UpdateDriveModal
