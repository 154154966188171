import React, { useEffect, useState } from 'react'
import { Modal, message } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import {
  generateRoom,
  idleGenerateRoomStatus,
  setRoomToModify,
  updateRoom,
} from '../../features/Meetings/MeetingsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { setMoveMeetingModalOpen } from './redux/calendarSlice'
import { limitText } from '../../utils/Utils'
import dayjs from 'dayjs'
import { unescape } from 'html-escaper'
import { RoomInterface } from '../room/RoomInterface'
import { useAttemptsListener } from 'dev-masters-react-kit'
import { useToastContext } from '../../components/Toast/ToastContext'

export default function ModalMoveMeeting() {
  const { t } = useTranslation('meetings')
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const roomToModify = useSelector((state: RootState) => state.rooms.roomToModify)
  const updateRoomStatus = useSelector((state: RootState) => state.rooms.updateRoomStatus)
  const moveMeetingModalOpen = useSelector(
    (state: RootState) => state.calendar.moveMeetingModalOpen,
  )
  const [navigationForward, setNavigationForward] = useState<
    (room: RoomInterface) => void
  >((room: RoomInterface) => {})
  const generateRoomStatus = useSelector(
    (state: RootState) => state.rooms.generateRoomStatus,
  )
  const roomFetched = useSelector((state: RootState) => state.rooms.roomFetched)
  const [processCustomInteraction, setProcessCustomInteraction] = useState<boolean>(false)
  const customMeetingInteraction = (
    room: RoomInterface,
    callback: (room: RoomInterface) => void,
  ) => {
    if (room.id) {
      callback(room)
    } else {
      setProcessCustomInteraction(true)
      setNavigationForward(() => callback)
      dispatch(generateRoom(room))
    }
  }

  useAttemptsListener([
    [
      generateRoomStatus,
      {
        success: () => {
          if (processCustomInteraction) {
            navigationForward(roomFetched as RoomInterface)
            setProcessCustomInteraction(false)
          }
        },
        error: () =>
          ToastOpen({
            message: t('Error retrieving meeting information.'),
            type: 'error',
          }),
      },
      () => dispatch(idleGenerateRoomStatus()),
    ],
  ])

  /**
   *
   */
  function confirmedMoveRoom() {
    if (roomToModify) {
      customMeetingInteraction(roomToModify, (room: RoomInterface) => {
        updateRoom({
          ...roomToModify,
          id: room.id,
          startDate: roomToModify.newStartDate,
        })
      })
    }
  }

  useEffect(() => {
    if (moveMeetingModalOpen && updateRoomStatus === 'success') {
      dispatch(setMoveMeetingModalOpen(false))
      dispatch(setRoomToModify())
      ToastOpen({
        message: t('Successfully moved meeting.'),
        type: 'success',
      })
    }
  }, [updateRoomStatus])

  /**
   *
   */
  function onCancel() {
    dispatch(setMoveMeetingModalOpen(false))
    dispatch(setRoomToModify())
  }

  return (
    <Modal
      centered
      title={t('Move meeting')}
      open={moveMeetingModalOpen}
      onOk={confirmedMoveRoom}
      okText={t('Confirm', { ns: 'common' })}
      confirmLoading={updateRoomStatus === 'loading'}
      onCancel={onCancel}
      cancelText={t('Cancel', { ns: 'common' })}
    >
      <p>
        <Trans
          i18nKey={'MOVE_MEETING'}
          shouldUnescape={true}
          ns="calendar"
          values={{
            meetingTitle: unescape(limitText(roomToModify?.name)),
            meetingNewDate: dayjs(roomToModify?.startDate).format('dddd DD/MM/YYYY'),
            meetingNewHour: dayjs(roomToModify?.startDate).format('HH:mm'),
          }}
          components={{ bold: <strong /> }}
        />
      </p>
    </Modal>
  )
}
