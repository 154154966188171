import React, { useState, forwardRef, Ref, useImperativeHandle, useRef } from 'react'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import './groups.scss'
import { AppDispatch, RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { createGroup, fetchGroups, idleCreateGroupStatus } from './redux/groupsSlice'
import { useAttemptsListener } from 'dev-masters-react-kit'
import GroupForm, { GroupFormInterface, GroupFormValues } from './GroupForm'
import { useNavigate } from 'react-router-dom'
import { useToastContext } from '../../components/Toast/ToastContext'

export interface CreateGroupModalInterface {
  open: () => void
}
const CreateGroupModal = forwardRef(({}, ref: Ref<CreateGroupModalInterface>) => {
  useImperativeHandle(ref, () => ({
    open: () => setOpenCreateGroupModal(true),
  }))
  const { t } = useTranslation('groups')
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const navigate = useNavigate()
  const selectOrganization = (state: RootState) => state.organisation.users
  const organisation = useSelector((state: RootState) => state.organisation)
  let organizationParticipants = useSelector(selectOrganization)
  const [openCreateGroupModal, setOpenCreateGroupModal] = useState(false)
  const createGroupStatus = useSelector(
    (state: RootState) => state.groups.createGroupStatus,
  )

  useAttemptsListener([
    [
      createGroupStatus,
      {
        success: () => {
          ToastOpen({
            message: t('Group was successfully created.'),
            type: 'success',
          })
          dispatch(fetchGroups())
          setOpenCreateGroupModal(false)
        },
        GROUP_ALREADY_EXIST: () => {
          ToastOpen({
            message: t('Group already exist, please change group name.'),
            type: 'error',
          })
        },
        unknown_error: () => {
          ToastOpen({
            message: t('Error creating group..'),
            type: 'error',
          })
        },
      },
      () => dispatch(idleCreateGroupStatus()),
    ],
  ])

  function onGroupSubmit(values: GroupFormValues) {
    if (!organisation.organisation?.id) {
      throw new Error(
        'something went wrong: organisation.id = ' + organisation.organisation?.id,
      )
    }
    dispatch(createGroup(values))
    navigate('/groups')
  }

  const groupFormRef = useRef<GroupFormInterface>(null)
  return (
    <Modal
      centered
      open={openCreateGroupModal}
      destroyOnClose
      afterOpenChange={(open) => {
        if (!open) groupFormRef.current?.resetFields()
      }}
      title={t('Create a group', { ns: 'common' })}
      onOk={() => groupFormRef.current?.submit()}
      onCancel={() => setOpenCreateGroupModal(false)}
      okText={t('Create', { ns: 'common' })}
      cancelText={t('Cancel', { ns: 'common' })}
      width={'90em'}
    >
      <div className="modal-content-container">
        <GroupForm
          participantsToBeDisplayed={organizationParticipants || []}
          ref={groupFormRef}
          onGroupSubmit={onGroupSubmit}
        />
      </div>
    </Modal>
  )
})

CreateGroupModal.displayName = 'CreateGroupModal'
export default CreateGroupModal
