import React, { forwardRef, useState, Ref, useImperativeHandle, useRef } from 'react'
import { attemptReinitPassword, idleAttemptReinitPasswordStatus } from './redux/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, FormInstance, Input, Modal } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import { AppDispatch, RootState } from '../../store'
import { useAttemptsListener } from 'dev-masters-react-kit'
import ModalConfirmation, {
  ModalConfirmationInterface,
} from '../../components/Modal/ModalConfirmation'
import { useToastContext } from '../../components/Toast/ToastContext'

interface FormValues {
  email: string
}

const ModalResetPassword = forwardRef(({}, ref: Ref<ModalConfirmationInterface>) => {
  const [form] = Form.useForm<FormValues>()
  useImperativeHandle(ref, () => ({
    openModal: () => {
      setModalVisible(true)
      form.setFieldValue('email', form.getFieldsValue()['email'])
    },
    close: () => setModalVisible(false), //will not be called
    isOpen: () => {
      //will not be called
      return modalVisible
    },
  }))

  const attemptReinitPasswordStatus = useSelector(
    (state: RootState) => state.auth.attemptReinitPasswordStatus,
  )

  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const { t } = useTranslation('auth')
  const emailValue = form.getFieldValue('email')
  const [modalVisible, setModalVisible] = useState(false)
  const modalConfirm = useRef<ModalConfirmationInterface>(null)
  const handleConfirmClick = async () => {
    try {
      await form.validateFields()
      modalConfirm.current?.openModal()
    } catch (errorInfo) {
      console.log('The format of the email does not comply')
    }
  }

  useAttemptsListener([
    [
      attemptReinitPasswordStatus,
      {
        success: () => {
          setModalVisible(false)
        },
        unknown_error: () =>
          ToastOpen({
            message: t('Unexpected error. Please try later.'),
            type: 'error',
          }),
      },
      () => dispatch(idleAttemptReinitPasswordStatus()),
    ],
  ])

  return (
    <>
      <Modal
        centered
        title={t('Reinitialize your password')}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={
          <div className="reinit-password-modal-footer">
            <Button onClick={() => setModalVisible(false)}>
              {t('Cancel', { ns: 'common' })}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              form="reinit-password-form"
              loading={attemptReinitPasswordStatus === 'loading'}
              onClick={handleConfirmClick}
            >
              {t('Reinitialize')}
            </Button>
          </div>
        }
      >
        <FormResetPassword form={form} />
      </Modal>
      <ModalConfirmation
        title={t('Reinitialize your password')}
        ref={modalConfirm}
        okText="footer_Close"
      >
        <div className="pb-0_5rem pt-0_5rem">
          <Trans
            i18nKey="EMAIL_SENT"
            ns="auth"
            values={{
              email: emailValue,
            }}
            components={{ strong: <strong /> }}
          />
          <br />
          <i>
            {t(
              'If you do not receive this email, remember to check that it is not in your spam folder.',
            )}
            <br />
            {t('If there is any problem, please do not hesitate to contact us.')}
          </i>
        </div>
      </ModalConfirmation>
    </>
  )
})

function FormResetPassword({ form }: { form: FormInstance<FormValues> }) {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation('auth')

  const reinitPassword = (values: FormValues) => {
    dispatch(attemptReinitPassword({ email: values['email'] }))
  }
  return (
    <Form
      id="reinit-password-form"
      autoComplete="off"
      form={form}
      onFinish={reinitPassword}
      scrollToFirstError={true}
    >
      <Form.Item
        label={t('Email')}
        name="email"
        className="mt-1rem"
        rules={[
          { required: true, message: t('Please input your email') },
          {
            type: 'email',
            message: t('The format of the email does not comply', { ns: 'common' }),
            validateTrigger: 'onSubmit',
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  )
}

ModalResetPassword.displayName = 'ModalResetPassword'
export default ModalResetPassword
