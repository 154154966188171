import React from 'react'
import { Notification } from '../liveWidgetSlice'
import { useNavigate } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { LOGO } from '../../../assets/logos'
import NotificationItem from './NotificationItem'
import classNames from 'classnames'
import { Tooltip } from 'antd'
import { limitText } from '../../../utils/Utils'

interface Props {
  notification: Notification
  isRowTable?: boolean
}

export default function DriveModifiedNotificationItem({
  notification,
  isRowTable,
}: Props) {
  const navigate = useNavigate()

  const content = (
    <Trans
      i18nKey={notification.type}
      ns="notification"
      values={{ name: limitText(notification.misc['driveName'], 200) }}
      components={{ bold: <strong /> }}
      shouldUnescape={true}
    />
  )

  return (
    <NotificationItem
      notification={notification}
      onClick={() => {
        // redirect to notification.misc['driveId']
        navigate('/drives/home')
      }}
      icon={
        <LOGO.logo_auxadrive/>
      }
      isRowTable={isRowTable}
    >
      <Tooltip
        title={notification.misc['driveName'].length > 50 ? content : ''}
        overlayClassName="notification_tooltip"
      >
        <div
          className={classNames({
            notification_chat_text_container: true,
            notification_table_message_column_content_drive: isRowTable,
          })}
        >
          {content}
        </div>
      </Tooltip>
    </NotificationItem>
  )
}
