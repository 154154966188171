import React, { useEffect, useState, useCallback, useRef } from 'react'
import Layout from '../../components/Layout/Layout'
import { Calendar, View, dayjsLocalizer } from 'react-big-calendar'
import { useTranslation } from 'react-i18next'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import dayjs, { Dayjs } from 'dayjs'
import {
  RoomToModify,
  fetchPeriodicRooms,
  fetchRooms,
  generateRoom,
  idleDeleteGeneratedRoomStatus,
  idleDeletePeriodicRoomStatus,
  idleDeleteRoomStatus,
  idleFetchPeriodicRoomStatus,
  idleGenerateRoomStatus,
  idleUpdatePlanRoomStatus,
  idleUpdateRoomStatus,
  setPeriodicRooms,
  setRoomToDelete,
  setRoomToModify,
} from '../Meetings/MeetingsSlice'
import './calendar.scss'
import CustomToolbarCalendar from './CustomToolbarCalendar'
import { Button, ColorPicker, DatePicker, Popover, Space, Spin } from 'antd'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { useAttemptsListener } from 'dev-masters-react-kit'
import {
  MeetingType,
  idleUpdateColorStatus,
  reducedFetchMeetings,
  setMoveMeetingModalOpen,
  setNewMeeting,
  setUpdateMeetingDrawerOpen,
  updateColor,
} from './redux/calendarSlice'
import classNames from 'classnames'
import {
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineSchedule,
  AiOutlineVideoCameraAdd,
} from 'react-icons/ai'
import { IoTrashOutline } from 'react-icons/io5'
import { useNavigate, useSearchParams } from 'react-router-dom'
import NewMeetingDrawer from './NewMeetingDrawer'
import UpdateMeetingDrawer from './UpdateMeetingDrawer'
import ModalDeleteMeeting from '../../components/Modal/ModalDeleteMeeting'
import ModalMoveMeeting from './ModalMoveMeeting'
import {
  PendingRoomInterface,
  RoomInterface,
  RoomParticipant,
} from '../room/RoomInterface'
import { GoQuestion } from 'react-icons/go'
import { localFormatDate } from '../../utils/Utils'
import { meetingColor } from '../../components/RoomManage/RoomManage'
import CalendarMenu from './CalendarMenu'
import { unescape } from 'html-escaper'
import { idleCreatePeriodicRoomStatus } from '../Meetings/NewMeetingSlice'
import { useToastContext } from '../../components/Toast/ToastContext'

export interface CalendarEvent {
  start: Date
  end: Date
  title?: string
  color?: string
  participants?: RoomParticipant[]
  id?: string
  creator?: string
  meetingsType: MeetingType
  periodicRoomId?: string
}

function CalendarComponent() {
  const DnDCalendar = withDragAndDrop(Calendar)
  const { t } = useTranslation('calendar')
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const email = useSelector((state: RootState) => state.auth.email)
  const upcoming = useSelector((state: RootState) => state.rooms.rooms.upcoming.rooms)
  const upcomingStatus = useSelector(
    (state: RootState) => state.rooms.rooms.upcoming.fetchStatus,
  )
  const archived = useSelector((state: RootState) => state.rooms.rooms.archived.rooms)
  const archivedStatus = useSelector(
    (state: RootState) => state.rooms.rooms.archived.fetchStatus,
  )
  const pending = useSelector((state: RootState) => state.rooms.rooms.pending.rooms)
  const pendingStatus = useSelector(
    (state: RootState) => state.rooms.rooms.pending.fetchStatus,
  )
  const periodicUpcoming = useSelector(
    (state: RootState) => state.rooms.periodicRooms.upcoming.rooms,
  )
  const periodicUpcomingStatus = useSelector(
    (state: RootState) => state.rooms.periodicRooms.upcoming.fetchPeriodicRoomStatus,
  )
  const periodicArchived = useSelector(
    (state: RootState) => state.rooms.periodicRooms.archived.rooms,
  )
  const periodicArchivedStatus = useSelector(
    (state: RootState) => state.rooms.periodicRooms.archived.fetchPeriodicRoomStatus,
  )
  const [events, setEvents] = useState<CalendarEvent[]>()
  events?.forEach((event) => {
    event.title = unescape(event.title as string)
  })

  const createRoomStatus = useSelector(
    (state: RootState) => state.newMeeting.updateRoomStatus,
  )
  const createPeriodicRoomStatus = useSelector(
    (state: RootState) => state.newMeeting.createPeriodicRoomStatus,
  )
  const updateRoomStatus = useSelector((state: RootState) => state.rooms.updateRoomStatus)
  const updatePeriodicRoomStatus = useSelector(
    (state: RootState) => state.rooms.updatePeriodicRoomStatus,
  )

  const updatePlanRoomStatus = useSelector(
    (state: RootState) => state.rooms.updatePlanRoomStatus,
  )
  const createPlanRoomStatus = useSelector(
    (state: RootState) => state.newMeeting.updatePlanRoomStatus,
  )

  const deleteRoomStatus = useSelector((state: RootState) => state.rooms.deleteRoomStatus)
  const deleteGeneratedRoomStatus = useSelector(
    (state: RootState) => state.rooms.deleteGeneratedRoomStatus,
  )
  const deletePeriodicRoomStatus = useSelector(
    (state: RootState) => state.rooms.deletePeriodicRoomStatus,
  )

  const updateColorStatus = useSelector(
    (state: RootState) => state.calendar.updateColorStatus,
  )

  const [searchParams, setSearchParams] = useSearchParams()
  const localizer = dayjsLocalizer(dayjs)

  const [currentView, setCurrentView] = useState<View>()
  const [currentDate, setCurrentDate] = useState<string>()

  const minDate =
    searchParams.get('minDate') ??
    dayjs()
      .startOf(
        currentView === 'work_week' ||
          currentView === 'agenda' ||
          currentView === undefined
          ? 'week'
          : currentView,
      )
      .toISOString()
  const maxDate =
    searchParams.get('maxDate') ??
    dayjs()
      .endOf(
        currentView === 'work_week' ||
          currentView === 'agenda' ||
          currentView === undefined
          ? 'week'
          : currentView,
      )
      .add(1, 'milliseconds')
      .toISOString()

  const [navigationForward, setNavigationForward] = useState<
    (room: RoomInterface | undefined) => void
  >((room: RoomInterface | undefined) => {})
  const generateRoomStatus = useSelector(
    (state: RootState) => state.rooms.generateRoomStatus,
  )
  const roomFetched = useSelector((state: RootState) => state.rooms.roomFetched)
  const [processCustomInteraction, setProcessCustomInteraction] = useState<boolean>(false)
  const [eventFetched, setEventFetched] = useState<CalendarEvent | undefined>(undefined)
  const customMeetingInteraction = (
    event: CalendarEvent,
    callback: (room: RoomInterface | undefined) => void,
  ) => {
    if (event.id) {
      const mRoom = getRoomFromEvent(event, false)
      callback(mRoom)
    } else {
      setProcessCustomInteraction(true)
      setNavigationForward(() => callback)
      dispatch(
        generateRoom({
          startDate: event.start.toISOString(),
          periodicRoomId: event.periodicRoomId,
        }),
      )
    }
  }

  useEffect(() => {
    fetchMeetings()
  }, [searchParams])

  useEffect(() => {
    if (
      upcomingStatus === 'success' &&
      archivedStatus === 'success' &&
      pendingStatus === 'success' &&
      periodicUpcomingStatus === 'success' &&
      periodicArchivedStatus === 'success'
    ) {
      setMeetings()
    }
  }, [upcoming, archived, pending, periodicUpcoming, periodicArchived])

  useEffect(() => {
    if (processCustomInteraction) {
      setProcessCustomInteraction(false)
      if (eventFetched) {
        const room = getRoomFromEvent(eventFetched, false)
        navigationForward(room)
        setEventFetched(undefined)
      }
    }
  }, [periodicUpcoming])

  /**
   *
   */
  function fetchMeetings() {
    setCurrentView((searchParams.get('view') ?? 'week') as View)
    setCurrentDate(searchParams.get('currentDate') ?? dayjs().toString())
    dispatch(fetchRooms({ status: 'upcoming', minDate, maxDate }))
    dispatch(fetchRooms({ status: 'pending' }))
    dispatch(fetchRooms({ status: 'archived', minDate, maxDate }))
    dispatch(fetchPeriodicRooms({ status: 'upcoming', minDate, maxDate }))
    dispatch(fetchPeriodicRooms({ status: 'archived', minDate, maxDate }))
  }

  useAttemptsListener([
    [
      createRoomStatus,
      {
        success: () => {
          dispatch(fetchRooms({ status: 'upcoming', minDate, maxDate }))
          dispatch(
            reducedFetchMeetings({
              minDate: minDate,
              maxDate: maxDate,
            }),
          )
        },
      },
      () => dispatch(idleUpdateRoomStatus()),
    ],
    [
      createPeriodicRoomStatus,
      {
        success: () => {
          dispatch(
            reducedFetchMeetings({
              minDate: minDate,
              maxDate: maxDate,
            }),
          )
          dispatch(fetchPeriodicRooms({ status: 'upcoming', minDate, maxDate }))
        },
      },
      () => dispatch(idleCreatePeriodicRoomStatus()),
    ],
    [
      updateRoomStatus,
      {
        success: () => {
          dispatch(
            reducedFetchMeetings({
              minDate: minDate,
              maxDate: maxDate,
            }),
          )
          dispatch(fetchRooms({ status: 'upcoming', minDate, maxDate }))
          dispatch(fetchPeriodicRooms({ status: 'upcoming', minDate, maxDate }))
        },
      },
      () => dispatch(idleUpdateRoomStatus()),
    ],
    [
      updatePeriodicRoomStatus,
      {
        success: () => {
          dispatch(
            reducedFetchMeetings({
              minDate: minDate,
              maxDate: maxDate,
            }),
          )
          dispatch(fetchRooms({ status: 'upcoming', minDate, maxDate }))
          dispatch(fetchPeriodicRooms({ status: 'upcoming', minDate, maxDate }))
        },
      },
      () => dispatch(idleUpdateRoomStatus()),
    ],
    [
      updatePlanRoomStatus,
      {
        success: () => {
          dispatch(
            reducedFetchMeetings({
              minDate: minDate,
              maxDate: maxDate,
            }),
          )
          dispatch(fetchRooms({ status: 'pending' }))
        },
      },
      () => dispatch(idleUpdatePlanRoomStatus()),
    ],
    [
      createPlanRoomStatus,
      {
        success: () => {
          dispatch(
            reducedFetchMeetings({
              minDate: minDate,
              maxDate: maxDate,
            }),
          )
          dispatch(fetchRooms({ status: 'pending' }))
        },
      },
      () => dispatch(idleUpdatePlanRoomStatus()),
    ],
    [
      deleteRoomStatus,
      {
        success: () => {
          dispatch(
            reducedFetchMeetings({
              minDate: minDate,
              maxDate: maxDate,
            }),
          )
          dispatch(setRoomToDelete({ room: undefined, pending: false }))
          ToastOpen({
            message: t('Meeting was successfully deleted.', { ns: 'meetings' }),
            type: 'success',
          })
          fetchMeetings() //TODO : Fetch only type of deleted meeting
        },
        error: () => {
          ToastOpen({
            message: t('Error deleting meeting.', { ns: 'meetings' }),
            type: 'error',
          })
        },
      },
      () => dispatch(idleDeleteRoomStatus()),
    ],
    [
      deleteGeneratedRoomStatus,
      {
        success: () => {
          dispatch(
            reducedFetchMeetings({
              minDate: minDate,
              maxDate: maxDate,
            }),
          )
          dispatch(setRoomToDelete({ room: undefined, pending: false }))
          ToastOpen({
            message: t('Meeting was successfully deleted.', { ns: 'meetings' }),
            type: 'success',
          })
          fetchMeetings() //TODO : Fetch only type of deleted meeting
        },
        error: () => {
          ToastOpen({
            message: t('Error deleting meeting.', { ns: 'meetings' }),
            type: 'error',
          })
        },
      },
      () => dispatch(idleDeleteGeneratedRoomStatus()),
    ],
    [
      deletePeriodicRoomStatus,
      {
        success: () => {
          dispatch(
            reducedFetchMeetings({
              minDate: minDate,
              maxDate: maxDate,
            }),
          )
          dispatch(setRoomToDelete({ room: undefined, pending: false }))
          ToastOpen({
            message: t('Meetings were successfully deleted.', { ns: 'meetings' }),
            type: 'success',
          })
          fetchMeetings() //TODO : Fetch only type of deleted meeting
        },
        error: () => {
          ToastOpen({
            message: t('Error deleting meeting.', { ns: 'meetings' }),
            type: 'error',
          })
        },
      },
      () => dispatch(idleDeletePeriodicRoomStatus()),
    ],
    [
      updateColorStatus,
      {
        success: () => {
          dispatch(
            reducedFetchMeetings({
              minDate: minDate,
              maxDate: maxDate,
            }),
          )
          dispatch(fetchRooms({ status: 'upcoming', minDate, maxDate }))
          dispatch(fetchPeriodicRooms({ status: 'upcoming', minDate, maxDate }))
          dispatch(fetchRooms({ status: 'pending' }))
        },
        error: () => {
          ToastOpen({
            message: t('Error updating color.', { ns: 'meetings' }),
            type: 'error',
          })
        },
      },
      () => dispatch(idleUpdateColorStatus()),
    ],
    [
      generateRoomStatus,
      {
        success: () => {
          const fetchEvent: CalendarEvent = {
            id: roomFetched?.id,
            start: dayjs(roomFetched?.startDate).toDate(),
            end: dayjs(roomFetched?.startDate)
              .add(roomFetched?.duration || 30, 'minutes')
              .toDate(),
            title: roomFetched?.name,
            color: roomFetched?.color,
            participants: roomFetched?.participants,
            creator: roomFetched?.creator?.email,
            meetingsType: MeetingType.upcoming,
            periodicRoomId: roomFetched?.periodicRoomId,
          }

          // refresh the list
          const objIndex = periodicUpcoming.findIndex(
            (event) =>
              event.startDate === roomFetched?.startDate &&
              event.periodicRoomId === roomFetched?.periodicRoomId,
          )
          const perioUpcoUpdated = [...periodicUpcoming]
          if (roomFetched) {
            perioUpcoUpdated[objIndex] = roomFetched
          }
          dispatch(idleGenerateRoomStatus())
          dispatch(setPeriodicRooms({ status: 'upcoming', rooms: perioUpcoUpdated }))
          setEventFetched(fetchEvent)
        },
        error: () =>
          ToastOpen({
            message: t('Error retrieving meeting information.'),
            type: 'error',
          }),
      },
      () => dispatch(idleGenerateRoomStatus()),
    ],
  ])

  /**
   *
   * @param id
   */
  function updateRoom(room?: RoomInterface) {
    selectUpcomingRoom(room)
  }

  /**
   *
   * @param id
   */
  function updatePendingRoom(id: string) {
    selectPendingRoom(id)
  }

  /**
   *
   * @param id
   */
  function deleteUpcomingRoom(event: CalendarEvent) {
    const room = getRoomFromEvent(event, false)
    if (room) {
      dispatch(setRoomToDelete({ room, pending: false }))
    }
  }

  /**
   *
   * @param id
   */
  function votePendingRoom(id: string) {
    navigate(`/pending-meetings/${id}/vote`)
  }

  /**
   *
   * @param id
   */
  function deletePendingRoom(id: string) {
    const room = pending.find((event) => event.id === id)
    if (room) {
      dispatch(setRoomToDelete({ room, pending: true }))
    }
  }

  /**
   *
   * @param id
   */
  function deleteArchivedRoom(id: string) {
    const room = archived.find((event) => event.id === id)
    if (room) {
      dispatch(setRoomToDelete({ room, pending: false }))
    }
  }

  /**
   *
   * @param id
   */
  function visualizeRoom(id: string) {
    selectArchivedRoom(id)
  }

  /**
   * Handle slot select.
   * @param event
   */
  function handleSlotSelect({ start, end }: { start: Date; end: Date }) {
    const startDayjs = dayjs(start)
    if (startDayjs.isBefore(dayjs())) {
      ToastOpen({
        message: t('You cannot create a meeting, the date has passed.'),
        type: 'warning',
      })

      return
    }

    const endDayjs = dayjs(end)
    const duration = endDayjs.diff(startDayjs, 'minutes')
    dispatch(setNewMeeting({ meetingStartDate: startDayjs, meetingDuration: duration }))
  }

  /**
   * Handle displayed Color
   * @param event
   * @returns { String } color
   */
  function handleColorDisplayed(event: CalendarEvent) {
    return event.creator === email
      ? event.color ?? meetingColor[0].value
      : event.participants?.find((participant) => participant.email === email)?.color ??
          meetingColor[0].value
  }

  /**
   * Event style getter.
   * @param event
   */
  const eventStyleGetter = useCallback(
    (event: CalendarEvent) => {
      const styles = {
        backgroundColor:
          event.meetingsType === MeetingType.archived
            ? 'black'
            : event.meetingsType === MeetingType.pending
              ? '#6bafe3'
              : handleColorDisplayed(event),
      }

      return { style: styles }
    },
    [email],
  )

  /**
   *
   * @param e
   */
  function onEventDrop({
    start,
    calendarEvent,
  }: {
    start: Date
    calendarEvent: CalendarEvent
  }) {
    const currentStartDate = dayjs(calendarEvent.start)
    if (calendarEvent.meetingsType === MeetingType.archived) {
      ToastOpen({
        message: t('You cannot move this meeting, it has already been archived.'),
        type: 'warning',
      })
      return
    }
    const newStartDate = dayjs(start)
    if (newStartDate.isBefore(dayjs())) {
      ToastOpen({
        message: t('You cannot move this meeting to a passed date.'),
        type: 'warning',
      })
      return
    }
    const room = getRoomFromEvent(calendarEvent, false)
    if (room) {
      const newValues: RoomToModify = {
        id: room.id || '',
        name: room.name,
        reference: room.reference,
        color: room.color,
        startDate: room.startDate,
        duration: room.duration,
        creatorNote: room.creatorNote,
        participants: room.participants,
        invited: room.invited,
        periodicRoomId: room.periodicRoomId,
        newStartDate: start.toISOString(),
      }
      dispatch(setRoomToModify(newValues))
      dispatch(setMoveMeetingModalOpen(true))
    }
  }

  /**
   *
   * @param id : selected archived room ID
   */
  function selectArchivedRoom(id?: string) {
    dispatch(setUpdateMeetingDrawerOpen(true))
    const room = archived.concat(periodicArchived).find((event) => event.id === id)
    if (room) {
      dispatch(setRoomToModify(room))
    }
  }

  const getRoomFromEvent = (event?: CalendarEvent, isArchived?: boolean) => {
    if (isArchived) {
      return archived.concat(periodicArchived).find((evt) => evt.id === event?.id)
    } else {
      const room = upcoming
        .concat(periodicUpcoming)
        .find(
          (rm) =>
            rm.id === event?.id &&
            rm.startDate === event?.start.toISOString() &&
            rm.periodicRoomId === event?.periodicRoomId,
        )
      if (room) {
        return room
      }
    }
  }

  /**
   *
   * @param id : selected upcoming room ID
   */
  function selectUpcomingRoom(room?: RoomInterface) {
    dispatch(setUpdateMeetingDrawerOpen(true))
    if (room) {
      dispatch(setRoomToModify(room))
    }
  }

  /**
   *
   * @param id : selected pending room ID
   */
  function selectPendingRoom(id?: string) {
    dispatch(setUpdateMeetingDrawerOpen(true))
    const room = pending.find((event) => event.id === id) as PendingRoomInterface
    if (room) {
      dispatch(
        setRoomToModify({
          ...room,
          plannedDates:
            room.voteStatus?.dates.map((date) => new Date(date).getTime()) || [],
        }),
      )
    }
  }

  /**
   *
   * @param e
   */
  function onSelectEvent(calendarEvent: CalendarEvent) {
    if (calendarEvent.meetingsType === MeetingType.pending) {
      selectPendingRoom(calendarEvent.id)
    } else if (calendarEvent.meetingsType === MeetingType.upcoming) {
      const room = getRoomFromEvent(calendarEvent, false)
      selectUpcomingRoom(room)
    } else {
      selectArchivedRoom(calendarEvent.id)
    }
  }

  /**
   *
   */
  function setMeetings() {
    const upcomingCalendarEvents: CalendarEvent[] = []
    const pendingCalendarEvents: CalendarEvent[] = []
    const archivedCalendarEvents: CalendarEvent[] = []
    const periodicUpcomingCalendarEvents: CalendarEvent[] = []
    const periodicArchivedCalendarEvents: CalendarEvent[] = []
    if (upcoming) {
      for (const rdv of upcoming) {
        if (rdv.duration) {
          upcomingCalendarEvents.push({
            start: dayjs(rdv.startDate).toDate(),
            end: dayjs(rdv.startDate).add(rdv.duration, 'minute').toDate(),
            title: rdv.name,
            color: rdv.color,
            participants: rdv.participants,
            id: rdv.id,
            meetingsType: MeetingType.upcoming,
            creator: rdv.creator?.email,
          })
        }
      }
    }

    if (pending) {
      for (const rdv of pending as PendingRoomInterface[]) {
        rdv.voteStatus?.dates.forEach((date) => {
          if (rdv.duration) {
            pendingCalendarEvents.push({
              start: dayjs(date).toDate(),
              end: dayjs(date).add(rdv.duration, 'minute').toDate(),
              title: rdv.name,
              id: rdv.id,
              meetingsType: MeetingType.pending,
              creator: rdv.creator?.email,
              color: rdv.color,
              participants: rdv.participants,
            })
          }
        })
      }
    }

    if (archived) {
      for (const rdv of archived) {
        if (rdv.duration) {
          archivedCalendarEvents.push({
            start: dayjs(rdv.startDate).toDate(),
            end: dayjs(rdv.startDate).add(rdv.duration, 'minute').toDate(),
            title: rdv.name,
            id: rdv.id,
            meetingsType: MeetingType.archived,
            creator: rdv.creator?.email,
          })
        }
      }
    }

    if (periodicUpcoming) {
      for (const rdv of periodicUpcoming) {
        if (rdv.duration) {
          periodicUpcomingCalendarEvents.push({
            start: dayjs(rdv.startDate).toDate(),
            end: dayjs(rdv.startDate).add(rdv.duration, 'minute').toDate(),
            title: rdv.name,
            id: rdv.id,
            color: rdv.color,
            meetingsType: MeetingType.upcoming,
            creator: rdv.creator?.email,
            periodicRoomId: rdv.periodicRoomId,
          })
        }
      }
    }

    if (periodicArchived) {
      for (const rdv of periodicArchived) {
        if (rdv.duration) {
          periodicArchivedCalendarEvents.push({
            start: dayjs(rdv.startDate).toDate(),
            end: dayjs(rdv.startDate).add(rdv.duration, 'minute').toDate(),
            title: rdv.name,
            id: rdv.id,
            meetingsType: MeetingType.archived,
            creator: rdv.creator?.email,
            periodicRoomId: rdv.periodicRoomId,
          })
        }
      }
    }

    setEvents([
      ...upcomingCalendarEvents,
      ...archivedCalendarEvents,
      ...pendingCalendarEvents,
      ...periodicUpcomingCalendarEvents,
      ...periodicArchivedCalendarEvents,
    ])
  }

  // TODO: Depends of the role
  const EventWrapper = ({ event, children }: any) => {
    const content = (
      <div>
        <div className="d-flex d-flex-column">
          <p className="mb-0">
            <b>{t('Date', { ns: 'common' })} :</b> {localFormatDate(dayjs(event.start))}
          </p>
          <p>
            <b>{t('Organizer', { ns: 'common' })} :</b> {event.creator}
          </p>
        </div>
        {event.meetingsType === MeetingType.upcoming ? (
          <Space className="d-flex d-flex-middle d-flex-center" wrap>
            <Button
              type="primary"
              icon={<AiOutlineVideoCameraAdd />}
              onClick={() =>
                customMeetingInteraction(event, (room: RoomInterface | undefined) =>
                  navigate(`/room/${room?.id}`),
                )
              }
            />
            <Button
              type="primary"
              icon={email === event.creator ? <AiOutlineEdit /> : <AiOutlineEye />}
              onClick={() =>
                customMeetingInteraction(event, (room: RoomInterface | undefined) => {
                  updateRoom(room)
                })
              }
            />
            <ColorPicker
              className="colorPicker-calendar"
              panelRender={(_, { components: { Presets } }) => <Presets />}
              trigger="hover"
              defaultValue={handleColorDisplayed(event)}
              presets={[{ label: '', colors: meetingColor.map((col) => col.value) }]}
              onChange={(color) =>
                customMeetingInteraction(event, (room: RoomInterface | undefined) => {
                  dispatch(
                    updateColor({
                      color: color.toHexString(),
                      meetingId: room?.id as string,
                    }),
                  )
                })
              }
              disabled={event.meetingsType === MeetingType.archived ? true : false}
            />
            <Button
              icon={<IoTrashOutline />}
              className="btn-danger"
              onClick={() => deleteUpcomingRoom(event)}
            />
          </Space>
        ) : event.meetingsType === MeetingType.pending ? (
          <Space className="d-flex d-flex-middle d-flex-center" wrap>
            <Button
              type="primary"
              icon={<AiOutlineSchedule onClick={() => votePendingRoom(event.id)} />}
            />
            <Button
              type="primary"
              icon={email === event.creator ? <AiOutlineEdit /> : <AiOutlineEye />}
              onClick={() => updatePendingRoom(event.id)}
            />
            <ColorPicker
              className="colorPicker-calendar"
              panelRender={(_, { components: { Presets } }) => <Presets />}
              trigger="hover"
              defaultValue={handleColorDisplayed(event)}
              presets={[{ label: '', colors: meetingColor.map((col) => col.value) }]}
              onChange={(color) => {
                dispatch(
                  updateColor({
                    color: color.toHexString(),
                    meetingId: event.id as string,
                  }),
                )
              }}
              disabled={event.meetingsType === MeetingType.archived ? true : false}
            />
            <Button
              icon={<IoTrashOutline />}
              className="btn-danger"
              onClick={() => deletePendingRoom(event.id)}
            />
          </Space>
        ) : (
          <Space className="d-flex d-flex-middle d-flex-center" wrap>
            <Button
              type="primary"
              icon={<AiOutlineEye />}
              onClick={() => visualizeRoom(event.id)}
            />
            <Button
              icon={<IoTrashOutline />}
              className="btn-danger"
              onClick={() => deleteArchivedRoom(event.id)}
            />
          </Space>
        )}
      </div>
    )
    return (
      <Popover content={content} title={event.title} trigger="hover">
        <div className="d-flex d-flex-between">
          <p
            style={{
              color: 'white',
              display: '-webkit-box',
              WebkitLineClamp: Math.floor((event.end - event.start) / 1000000),
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              wordBreak: 'break-all',
            }}
          >
            {event.title}
          </p>
          {event.meetingsType === MeetingType.pending && <GoQuestion size="0.9em" />}
        </div>
      </Popover>
    )
  }

  const tooltipAccessor = useCallback((event: any) => '', []) //Hide default tooltip of the calendar

  function renderCalendarMenu() {
    return (
      <CalendarMenu selectedDate={currentDate || ''} pickedDateSetter={setCurrentDate} />
    )
  }

  function renderCalendarContent() {
    return (
      <div>
        {currentView && currentDate && (
          <DnDCalendar
            className={classNames({
              'calendar-opacity':
                archivedStatus === 'loading' ||
                upcomingStatus === 'loading' ||
                pendingStatus === 'loading',
            })}
            view={currentView}
            date={currentDate}
            localizer={localizer}
            selectable
            resizable={false}
            onDoubleClickEvent={(e) => onSelectEvent(e as CalendarEvent)}
            events={events || []}
            onSelectSlot={handleSlotSelect}
            eventPropGetter={(e) => eventStyleGetter(e as CalendarEvent)}
            dayLayoutAlgorithm="no-overlap"
            tooltipAccessor={tooltipAccessor}
            style={{ height: '86vh' }}
            components={{
              toolbar: () => (
                <CustomToolbarCalendar
                  currentView={currentView}
                  currentDate={currentDate}
                  setCurrentDate={setCurrentDate}
                />
              ),
              event: EventWrapper,
            }}
            onEventDrop={({ start, event }) => {
              const startDate = new Date(start) // Convertir la valeur 'start' en objet Date // Может лучше оставлять комментарии на английском?
              onEventDrop({ start: startDate, calendarEvent: event as CalendarEvent })
            }}
            scrollToTime={new Date(0, 0, 0, 8, 0, 0)}
          />
        )}

        {(archivedStatus === 'loading' ||
          upcomingStatus === 'loading' ||
          pendingStatus === 'loading') && (
          <div className="loading-container">
            <Spin />
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <Layout
        title="Planning"
        headerTitle={<h1 className="title">{t('Planning')}</h1>}
        menu={renderCalendarMenu()}
        content={renderCalendarContent()}
      />

      <NewMeetingDrawer />

      <UpdateMeetingDrawer />

      <ModalDeleteMeeting />

      <ModalMoveMeeting />
    </>
  )
}

export default CalendarComponent
