import { FaQuestionCircle as FAQ } from "react-icons/fa"
import { IoDocumentText as logo_auxadoc } from "react-icons/io5";
import { FaHardDrive as logo_auxadrive} from "react-icons/fa6";
import { FaFileVideo as logo_auxameetings} from "react-icons/fa6";
import { FaCalendarDays as logo_auxaplanning} from "react-icons/fa6";
import { TiMessages as logo_auxateam } from "react-icons/ti";
import { FaShareFromSquare as logo_auxatransfer } from "react-icons/fa6";
import { FaPeopleGroup as logo_groups } from "react-icons/fa6";
import { MdGroups2 as logo_users} from "react-icons/md";
import { BsMenuButtonWideFill as menu } from "react-icons/bs";
import { FiZoomIn as zoom_in} from "react-icons/fi";
import { FiZoomOut as zoom_out} from "react-icons/fi";


export const LOGO = {
  FAQ,
  logo_auxadoc,
  logo_auxadrive,
  logo_auxameetings,
  logo_auxaplanning,
  logo_auxateam,
  logo_auxatransfer,
  logo_groups,
  logo_users,
  menu,
  zoom_in,
  zoom_out,
}
