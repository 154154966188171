import React from 'react'
import { Modal } from 'antd'
import { Trans, useTranslation } from 'react-i18next'
import {
  FileInterface,
  deleteDriveFile,
  idleDeleteDriveFileStatus,
} from '../../features/Drives/redux/drivesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { useAttemptsListener } from 'dev-masters-react-kit'
import { limitText } from '../../utils/Utils'
import { useToastContext } from '../Toast/ToastContext'

interface Props {
  openDeleteFileModal: boolean
  setOpenDeleteFileModal: (open: boolean) => void
  fileToDelete?: FileInterface
  startIndex?: number
  currentPage?: number
  setCurrentPage?: (page: number) => void
}

function DeleteFileModal({
  openDeleteFileModal,
  setOpenDeleteFileModal,
  fileToDelete,
  startIndex,
  currentPage,
  setCurrentPage,
}: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation('drives')
  const selectedDriveFolder = useSelector(
    (state: RootState) => state.drive.selectedDriveFolder,
  )
  const deleteDriveFileStatus = useSelector(
    (state: RootState) => state.drive.deleteDriveFileStatus,
  )
  const { ToastOpen } = useToastContext()
  useAttemptsListener([
    [
      deleteDriveFileStatus,
      {
        success: () => {
          ToastOpen({
            message: t('File was successfully removed.'),
            type: 'success',
          })
          setOpenDeleteFileModal(false)
          if (
            selectedDriveFolder &&
            startIndex ==
              selectedDriveFolder.childrenFolders.length +
                selectedDriveFolder.childrenFiles.length
          ) {
            if (setCurrentPage && currentPage) {
              setCurrentPage(currentPage - 1)
            }
          }
        },
        NOT_ENOUGH_FILE_RIGHT: () => {
          ToastOpen({
            message: t(
              'You do not have the necessary rights to delete files in this project.',
            ),
            type: 'error',
          })
        },
        FETCH_ERROR: () => {
          ToastOpen({
            message: t('The drive service is not available at the moment.'),
            type: 'error',
            duration: 50,
          })
        },
        unknown_error: () => {
          ToastOpen({
            message: t('Error deleting file.'),
            type: 'error',
          })
        },
      },
      () => dispatch(idleDeleteDriveFileStatus()),
    ],
  ])

  /**
   *
   */
  function confirmDeleteFile() {
    if (selectedDriveFolder && fileToDelete) {
      dispatch(
        deleteDriveFile({
          driveId: selectedDriveFolder?.driveId,
          folderId: selectedDriveFolder?.id,
          fileId: fileToDelete.id,
        }),
      )
    }
  }
  return (
    <Modal
      centered
      open={openDeleteFileModal}
      title={t('Delete file')}
      onOk={confirmDeleteFile}
      onCancel={() => setOpenDeleteFileModal(false)}
      okText={t('Yes', { ns: 'common' })}
      cancelText={t('No', { ns: 'common' })}
    >
      <p>
        <Trans
          ns="drives"
          i18nKey="DELETE_FILE"
          shouldUnescape={true}
          values={{
            name: limitText(fileToDelete?.name),
          }}
          components={{ bold: <strong /> }}
        />
      </p>
    </Modal>
  )
}

export default DeleteFileModal
