import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'antd'
import { useAttemptsListener } from 'dev-masters-react-kit'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import GroupForm, {
  ChangedParticipants,
  GroupFormInterface,
  GroupFormValues,
} from './GroupForm'
import './groups.scss'
import {
  fetchGroupParticipants,
  fetchGroups,
  idleUpdateGroupStatus,
  setGroupToModify,
  updateGroup,
} from './redux/groupsSlice'
import { fetchUsers, idleUsers } from '../Organisation/redux/organisationSlice'
import { useToastContext } from '../../components/Toast/ToastContext'

function UpdateGroupModal() {
  const { t } = useTranslation('groups')

  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const organisation = useSelector((state: RootState) => state.organisation)
  const groupToModify = useSelector(
    (state: RootState) => state.groups.groupToModify?.group,
  )
  const readonly = useSelector((state: RootState) => state.groups.groupToModify?.readonly)

  const updateGroupStatus = useSelector(
    (state: RootState) => state.groups.updateGroupStatus,
  )
  const groupFormRef = useRef<GroupFormInterface>(null)
  const email = useSelector((state: RootState) => state.auth.email)
  const selectOrganization = (state: RootState) => state.organisation.users
  let organizationParticipants = useSelector(selectOrganization)
  let participantsModify = groupToModify?.participants

  const findAndFilter = <T extends { email: string }>(
    participants: T[] | undefined,
  ): T[] => {
    const me = participants?.find((p) => p.email === email)
    const others = participants?.filter((p) => p.email !== email)
    return me && others ? [me, ...others] : participants || []
  }

  organizationParticipants = findAndFilter(organizationParticipants)
  participantsModify = findAndFilter(participantsModify)

  const [pageDetails, setPageDetails] = useState<{ page: number; usersPerPage: number }>({
    page: 1,
    usersPerPage: 10,
  })

  function updateUsers() {
    //TODO: update licenses on remove/attach users
    dispatch(
      fetchUsers({
        page: pageDetails.page,
        usersPerPage: pageDetails.usersPerPage,
      }),
    )
  }

  useEffect(() => {
    //TODO: refactoring (don't fetch previously fetched pages)
    updateUsers()
    return () => {
      dispatch(idleUsers())
    }
  }, [pageDetails])

  useAttemptsListener([
    [
      updateGroupStatus,
      {
        success: () => {
          ToastOpen({
            message: t('Group was successfully updated.'),
            type: 'success',
          })
          dispatch(fetchGroups())
          dispatch(setGroupToModify(undefined))
        },
        GROUP_ALREADY_EXIST: () => {
          ToastOpen({
            message: t('Group already exists, please change group name.'),
            type: 'error',
          })
        },
        unknown_error: () => {
          ToastOpen({
            message: t('Error updating group.'),
            type: 'error',
          })
        },
      },
      () => dispatch(idleUpdateGroupStatus()),
    ],
  ])

  /**
   *
   */
  function onGroupEdit(
    values: GroupFormValues,
    changedParticipants: ChangedParticipants,
  ) {
    if (!groupToModify) return
    dispatch(
      updateGroup({
        groupId: groupToModify.id,
        ...values,
        ...changedParticipants,
      }),
    )
  }

  return (
    <Modal
      centered
      open={groupToModify !== undefined}
      destroyOnClose
      afterOpenChange={(isOpen) => {
        if (!organisation.organisation?.id || !groupToModify) return
        dispatch(
          fetchGroupParticipants({
            organizationId: organisation.organisation?.id,
            groupId: groupToModify.id,
          }),
        )
      }}
      title={groupToModify && readonly ? t('View group') : t('Modify group')}
      onOk={() => groupFormRef.current?.submit()}
      onCancel={() => dispatch(setGroupToModify(undefined))}
      okText={t('Modify')}
      cancelText={
        groupToModify && readonly
          ? t('Close', { ns: 'common' })
          : t('Cancel', { ns: 'common' })
      }
      width={'90em'}
      okButtonProps={groupToModify && readonly ? { style: { display: 'none' } } : {}}
    >
      <div className="modal-content-container">
        <GroupForm
          ref={groupFormRef}
          participantsToBeDisplayed={
            !readonly ? organizationParticipants || [] : participantsModify || []
          }
          onGroupSubmit={onGroupEdit}
        />
      </div>
    </Modal>
  )
}

export default UpdateGroupModal
