import React, {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  forwardRef,
  useImperativeHandle,
} from 'react'
import Draggable from 'react-draggable'
import {
  Alert,
  Button,
  Col,
  ColorPicker,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Card,
  Space,
} from 'antd'
import {
  attemptGetPersonalization,
  attemptUpdatePersonalization,
  idlePersonalizationGetStatus,
  idlePersonalizationUpdateStatus,
  PersonalizationValues,
} from './PersonalizationSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import {
  authLogoFilename,
  emailLogoFilename,
  backgroundImageFilename,
  callLogoFilename,
  headerLogoFilename,
  headerLogoMobileFilename,
} from '../../assets/personnalization'
import { useTranslation } from 'react-i18next'
import { IoTrashOutline } from 'react-icons/io5'
import Dragger from 'antd/lib/upload/Dragger'
import { useNavigate } from 'react-router-dom'
import { BiImageAdd } from 'react-icons/bi'
import { RiCloseCircleLine, RiDragDropLine } from 'react-icons/ri'
import { PersonalizationUploaderInterface } from '../../utils/hooks/usePersonalizationUploader'
import { useAttemptsListener } from 'dev-masters-react-kit'
import usePersonalizationStyles from '../../utils/hooks/usePersonalizationStyles'
import './Preview.scss'
import './Views/views.scss'
import loginLogo from './assets/auxamed_top_light.svg'
import emailLogo from './assets/auxamed_top_light.svg'
import loginBackground from './assets/back.jpg'
import headerLogo from './assets/auxamed_inline.svg'
import headerLogoMobile from './assets/auxamed_mobile.png'
import callLogo from './assets/auxamed_top_light.svg'
import icon from './assets/icon.png'
import { useToastContext } from '../../components/Toast/ToastContext'

export interface DesignerToolsInterface {
  toggleShowState: () => void
}

interface Props {
  setDisplayedView: (view: 'auth' | 'layout' | 'antechamber' | 'call') => void
  personalizationUploader: PersonalizationUploaderInterface
}

const DesignerTools = forwardRef((props: Props, ref) => {
  const { setDisplayedView, personalizationUploader } = props
  const auth = useSelector((state: RootState) => state.auth)

  useImperativeHandle(ref, () => ({
    toggleShowState: toggleShowState,
  }))

  const {
    authLogoUploaded,
    setAuthLogoUploaded,
    authLogoUploadedFileName,
    setAuthLogoUploadedFileName,
    authBackgroundUploaded,
    setAuthBackgroundUploaded,
    authBackgroundUploadedFileName,
    setAuthBackgroundUploadedFileName,
    headerLogoUploaded,
    setHeaderLogoUploaded,
    headerLogoUploadedFileName,
    setHeaderLogoUploadedFileName,
    headerLogoMobileUploaded,
    setHeaderLogoMobileUploaded,
    headerLogoMobileUploadedFileName,
    setHeaderLogoMobileUploadedFileName,
    emailLogoUploaded,
    setEmailLogoUploaded,
    emailLogoUploadedFileName,
    setEmailLogoUploadedFileName,
    callLogoUploaded,
    setCallLogoUploaded,
    callLogoUploadedFileName,
    setCallLogoUploadedFileName,
    iconUploaded,
    setIconUploaded,
    iconUploadedFileName,
    setIconUploadedFileName,
    clear,
    loginLogoDraggerProps,
    emailLogoDraggerProps,
    loginBackgroundDraggerProps,
    headerLogoDraggerProps,
    headerLogoMobileDraggerProps,
    callLogoDraggerProps,
    iconDraggerProps,
  } = personalizationUploader

  function toggleShowState(close = false) {
    setDesignerToolsOpen((prev) => close || !prev)
  }
  const [designerToolsOpen, setDesignerToolsOpen] = useState(true)

  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const [form] = Form.useForm()
  const { t } = useTranslation(['common', 'personalization'])
  const navigate = useNavigate()
  const buildInProgress = useSelector(
    (state: RootState) => state.personalization.buildInProgress,
  )
  const getStatus = useSelector((state: RootState) => state.personalization.getStatus)
  const updateStatus = useSelector(
    (state: RootState) => state.personalization.updateStatus,
  )

  const jwt = useSelector((state: RootState) => state.auth.jwt || state.invite.jwt || '')

  const {
    primaryColor,
    setPrimaryColor,
    primaryColorText,
    setPrimaryColorText,
    secondaryColor,
    setSecondaryColor,
    secondaryColorText,
    setSecondaryColorText,
    tertiaryColor,
    setTertiaryColor,
    tertiaryColorText,
    setTertiaryColorText,
  } = usePersonalizationStyles(authBackgroundUploaded, authLogoUploadedFileName, form)

  function restorePrimaryColor() {
    form.setFieldValue('primaryColor', '#224160')
    setPrimaryColor('#224160')
  }

  function restorePrimaryColorText() {
    form.setFieldValue('primaryColorText', '#FFFFFF')
    setPrimaryColorText('#FFFFFF')
  }

  function restoreSecondaryColor() {
    form.setFieldValue('secondaryColor', '#1591ff')
    setSecondaryColor('#1591ff')
  }

  function restoreSecondaryColorText() {
    form.setFieldValue('secondaryColorText', '#FFFFFF')
    setSecondaryColorText('#FFFFFF')
  }

  function restoreTertiaryColor() {
    form.setFieldValue('tertiaryColor', '#a8bbcc')
    setTertiaryColor('#a8bbcc')
  }

  function restoreTertiaryColorText() {
    form.setFieldValue('tertiaryColorText', '#FFFFFF')
    setTertiaryColorText('#FFFFFF')
  }

  useAttemptsListener(
    [
      [
        updateStatus,
        {
          error: () =>
            ToastOpen({
              message: t('An error occurred. Please try later.', { ns: 'common' }),
              type: 'error',
            }),
          success: () => {
            ToastOpen({
              message: t(
                'Personalisation was successfully launched. It usually takes 5 minutes to take effect.',
                { ns: 'personalization' },
              ),
              type: 'info',
            })
            clear()
          },
        },
      ],
    ],
    () => {
      dispatch(idlePersonalizationGetStatus())
      dispatch(idlePersonalizationUpdateStatus())
    },
    () => {
      if (jwt) {
        dispatch(attemptGetPersonalization({ jwt }))
      }
    },
  )

  async function resetToDefault() {
    form.setFieldValue('primaryColor', '#224160')
    setPrimaryColor('#224160')
    form.setFieldValue('primaryColorText', '#FFFFFF')
    setPrimaryColorText('#FFFFFF')
    form.setFieldValue('secondaryColor', '#1591ff')
    setSecondaryColor('#1591ff')
    form.setFieldValue('secondaryColorText', '#FFFFFF')
    setSecondaryColorText('#FFFFFF')
    form.setFieldValue('tertiaryColor', '#a8bbcc')
    setTertiaryColor('#a8bbcc')
    form.setFieldValue('tertiaryColorText', '#FFFFFF')
    setTertiaryColorText('#FFFFFF')
    const handleLogoFetch = async (
      url: any,
      formDataName: any,
      dispatchFile: any,
      dispatchFileName: any,
      fileName: any,
    ) => {
      const response = await fetch(url)
      const blob = await response.blob()
      const formData = new FormData()
      formData.append(formDataName, blob, fileName)

      return fetch(
        `${process.env.REACT_APP_BASE_PERSONALIZATION_URL}/upload/${formDataName}`,
        {
          method: 'POST',
          headers: {
            Authorization: `${auth.jwt}`,
          },
          body: formData,
        },
      ).then(() => {
        dispatchFile(url)
        dispatchFileName(fileName)
      })
    }

    const requests = [
      handleLogoFetch(
        loginLogo,
        'login_logo',
        setAuthLogoUploaded,
        setAuthLogoUploadedFileName,
        'login_logo.svg',
      ),
      handleLogoFetch(
        emailLogo,
        'logo_email',
        setEmailLogoUploaded,
        setEmailLogoUploadedFileName,
        'logo_email.svg',
      ),
      handleLogoFetch(
        loginBackground,
        'login_background',
        setAuthBackgroundUploaded,
        setAuthBackgroundUploadedFileName,
        'login_background.jpeg',
      ),
      handleLogoFetch(
        headerLogo,
        'header_logo',
        setHeaderLogoUploaded,
        setHeaderLogoUploadedFileName,
        'header_logo.svg',
      ),
      handleLogoFetch(
        headerLogoMobile,
        'header_logo_mobile',
        setHeaderLogoMobileUploaded,
        setHeaderLogoMobileUploadedFileName,
        'header_logo_mobile.png',
      ),
      handleLogoFetch(
        callLogo,
        'call_logo',
        setCallLogoUploaded,
        setCallLogoUploadedFileName,
        'call_logo.svg',
      ),
      handleLogoFetch(icon, 'icon', setIconUploaded, setIconUploadedFileName, 'icon.png'),
    ]

    await Promise.all(requests)
  }

  function onFinish(values: PersonalizationValues) {
    dispatch(
      attemptUpdatePersonalization({
        ...values,
        replaceLoginLogo: authLogoUploadedFileName,
        replaceEmailLogo: emailLogoUploadedFileName,
        replaceLoginBackground: authBackgroundUploadedFileName,
        replaceHeaderLogo: headerLogoUploadedFileName,
        replaceHeaderLogoMobile: headerLogoMobileUploadedFileName,
        replaceCallLogo: callLogoUploadedFileName,
        replaceIcon: iconUploadedFileName,
      }),
    )
  }

  return (
    <Draggable bounds="body" defaultPosition={{ x: 50, y: 50 }} handle="strong">
      <Card
        title={t('Personnalisation', { ns: 'personalization' })}
        style={{ width: 400, height: 850 }}
        className="design-tool"
        hidden={!designerToolsOpen}
        extra={
          <Space>
            <strong>
              <RiDragDropLine size={25} style={{ cursor: 'pointer' }} />
            </strong>
            <RiCloseCircleLine
              size={25}
              style={{ cursor: 'pointer' }}
              onClick={() => toggleShowState(false)}
            />
          </Space>
        }
      >
        {getStatus === 'loading' && <Spin />}
        {getStatus === 'error' && (
          <Alert
            message={t('Personnalisation is not enabled for your organisation.', {
              ns: 'personalization',
            })}
            type="error"
          />
        )}
        {getStatus === 'success' && (
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={form}
            onFinish={onFinish}
            disabled={updateStatus === 'loading' || buildInProgress}
            scrollToFirstError={true}
          >
            <h4 className="uppercase mb-05rem">
              {t('Page preview', { ns: 'personalization' })}
            </h4>
            <Row align="middle">
              <Col span={24}>
                <Select
                  className="d-flex"
                  defaultValue="auth"
                  onChange={(key: 'auth' | 'layout') => setDisplayedView(key)}
                >
                  <Select.Option value="auth" key="auth">
                    <span>{t('Login page', { ns: 'personalization' })}</span>
                  </Select.Option>
                  <Select.Option value="layout" key="layout">
                    <span>{t('Meetings page', { ns: 'personalization' })}</span>
                  </Select.Option>
                  <Select.Option value="antechamber" key="antechamber">
                    <span>{t('Antechamber', { ns: 'personalization' })}</span>
                  </Select.Option>
                  <Select.Option value="call" key="call">
                    <span>{t('Call page', { ns: 'personalization' })}</span>
                  </Select.Option>
                </Select>
              </Col>
            </Row>

            <h4 className="uppercase mb-05rem mt-1rem">
              {t('Images & logos', { ns: 'personalization' })}
            </h4>
            <Row align="middle" gutter={24} className="uploaded-image-container mt-0rem">
              <Col span={24}>
                <Dragger {...loginLogoDraggerProps} className="dragger">
                  <p className="ant-upload-text">
                    <strong>
                      {t('Logo used on the login page', { ns: 'personalization' })}
                    </strong>
                  </p>
                  <div className="d-flex d-flex-middle d-flex-center g-0_5rem ml-1_2rem">
                    <BiImageAdd size="4rem" />
                    <p className="ant-upload-hint align-left">
                      {t(
                        'This logo will be used for the login page. Prefer 200px x 300px resolution.',
                        { ns: 'personalization' },
                      )}
                    </p>
                  </div>
                  <img
                    className="uploaded-image-preview uploaded-image-preview-login-logo"
                    src={authLogoUploaded ?? `/logo/${authLogoFilename}`}
                    alt="Auth logo"
                  />
                </Dragger>
              </Col>
            </Row>

            <div className="hr mb-1rem" />

            <Row align="middle" gutter={24} className="uploaded-image-container mt-0rem">
              <Col span={24}>
                <Dragger {...emailLogoDraggerProps} className="dragger">
                  <p className="ant-upload-text">
                    <strong>{t('Logo used for email', { ns: 'personalization' })}</strong>
                  </p>
                  <div className="d-flex d-flex-middle d-flex-center g-0_5rem ml-1_2rem">
                    <BiImageAdd size="4rem" />
                    <p className="ant-upload-hint align-left">
                      {t(
                        'Only png images are accepted. This logo will be used for email. Prefer 200px x 300px resolution.',
                        { ns: 'personalization' },
                      )}
                    </p>
                  </div>
                  <img
                    className="uploaded-image-preview uploaded-image-preview-login-logo"
                    src={emailLogoUploaded ?? `/logo/${emailLogoFilename}`}
                    alt="Auth logo"
                  />
                </Dragger>
              </Col>
            </Row>

            <div className="hr mb-1rem" />

            <Row align="middle" gutter={24} className="uploaded-image-container">
              <Col span={24}>
                <Dragger {...loginBackgroundDraggerProps} className="dragger">
                  <p className="ant-upload-text">
                    <strong>
                      {t('Background image used on the login page', {
                        ns: 'personalization',
                      })}
                    </strong>
                  </p>
                  <div className="d-flex d-flex-middle d-flex-center g-0_5rem ml-1_2rem">
                    <BiImageAdd size="4rem" />
                    <p className="ant-upload-hint align-left">
                      {t(
                        'Only png and jpeg images are accepted. Prefer 200px x 300px resolution.',
                        { ns: 'personalization' },
                      )}
                    </p>
                  </div>
                  <img
                    className="custom-image-container"
                    src={authBackgroundUploaded ?? `/images/${backgroundImageFilename}`}
                    alt="Auth logo"
                  />
                </Dragger>
              </Col>
            </Row>

            <div className="hr mb-1rem" />

            <Row align="middle" gutter={24} className="uploaded-image-container">
              <Col span={24}>
                <Dragger {...headerLogoDraggerProps} className="dragger">
                  <p className="ant-upload-text">
                    <strong>
                      {t('Logo used in the application', { ns: 'personalization' })}
                    </strong>
                  </p>
                  <div className="d-flex d-flex-middle d-flex-center g-0_5rem ml-1_2rem">
                    <BiImageAdd size="4rem" />
                    <p className="ant-upload-hint align-left">
                      {t(
                        'Only png and jpeg images are accepted. Prefer 200px x 300px resolution.',
                        { ns: 'personalization' },
                      )}
                    </p>
                  </div>
                  <img
                    className="uploaded-image-preview uploaded-image-preview-header-logo"
                    src={headerLogoUploaded ?? `/logo/${headerLogoFilename}`}
                  />
                </Dragger>
              </Col>
            </Row>

            <div className="hr mb-1rem" />

            <Row align="middle" gutter={24} className="uploaded-image-container">
              <Col span={24}>
                <Dragger {...headerLogoMobileDraggerProps} className="dragger">
                  <p className="ant-upload-text">
                    <strong>
                      {t('Logo used in the mobile application', {
                        ns: 'personalization',
                      })}
                    </strong>
                  </p>
                  <div className="d-flex d-flex-middle d-flex-center g-0_5rem ml-1_2rem">
                    <BiImageAdd size="4rem" />
                    <p className="ant-upload-hint align-left">
                      {t(
                        'Only png and jpeg images are accepted. Prefer 200px x 300px resolution.',
                        { ns: 'personalization' },
                      )}
                    </p>
                  </div>
                  <img
                    className="uploaded-image-preview uploaded-image-preview-header-logo-mobile"
                    src={headerLogoMobileUploaded ?? `/logo/${headerLogoMobileFilename}`}
                  />
                </Dragger>
              </Col>
            </Row>

            <div className="hr mb-1rem" />

            <Row align="middle" gutter={24} className="uploaded-image-container">
              <Col span={24}>
                <Dragger {...callLogoDraggerProps} className="dragger">
                  <p className="ant-upload-text">
                    <strong>
                      {t('Logo used on the call page', { ns: 'personalization' })}
                    </strong>
                  </p>
                  <div className="d-flex d-flex-middle d-flex-center g-0_5rem ml-1_2rem">
                    <BiImageAdd size="4rem" />
                    <p className="ant-upload-hint align-left">
                      {t(
                        'Only png and jpeg images are accepted. Prefer 200px x 300px resolution.',
                        { ns: 'personalization' },
                      )}
                    </p>
                  </div>
                  <img
                    className="uploaded-image-preview uploaded-image-preview-call-logo"
                    src={callLogoUploaded ?? `/logo/${callLogoFilename}`}
                  />
                </Dragger>
              </Col>
            </Row>

            <div className="hr mb-1rem" />

            <Row align="middle" gutter={24} className="uploaded-image-container">
              <Col span={24}>
                <Dragger {...iconDraggerProps} className="dragger">
                  <p className="ant-upload-text">
                    <strong>{t('Application icon', { ns: 'personalization' })}</strong>
                  </p>
                  <div className="d-flex d-flex-middle d-flex-center g-0_5rem ml-1_2rem">
                    <BiImageAdd size="4rem" />
                    <p className="ant-upload-hint align-left">
                      {t(
                        'Only png images are accepted. Prefer 128px x 128px resolution.',
                        { ns: 'personalization' },
                      )}
                    </p>
                  </div>
                  <img
                    className="uploaded-image-preview uploaded-image-preview-icon"
                    src={iconUploaded ?? `/icon.png`}
                  />
                </Dragger>
              </Col>
            </Row>

            <h4 className="uppercase mt-1rem mb-05rem">
              {t('Colors of text, buttons & icons', { ns: 'personalization' })}
            </h4>

            <Form.Item
              name="title"
              label={t('Title', { ns: 'personalization' })}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <label className="personnalisation-form-label" htmlFor="primaryColor">
              {t('Primary color', { ns: 'personalization' })}
            </label>
            <div className="input-with-help">
              <Form.Item name="primaryColor" label={false} rules={[{ required: true }]}>
                <ColorPicker
                  onChange={(value, hex) => {
                    form.setFieldsValue({ primaryColor: hex })
                  }}
                  onChangeComplete={(color) => {
                    setPrimaryColor(color.toHexString())
                  }}
                  showText
                />
              </Form.Item>
              <IoTrashOutline
                className="color-picker-restore"
                onClick={restorePrimaryColor}
              />
              <div className="input-help">{t('Lorem ipsum')}</div>
            </div>

            <label className="personnalisation-form-label" htmlFor="primaryColorText">
              {t('Primary color text', { ns: 'personalization' })}
            </label>
            <div className="input-with-help">
              <Form.Item
                name="primaryColorText"
                label={false}
                rules={[{ required: true }]}
              >
                <ColorPicker
                  onChange={(value, hex) => {
                    form.setFieldsValue({ primaryColorText: hex })
                  }}
                  onChangeComplete={(color) => {
                    setPrimaryColorText(color.toHexString())
                  }}
                  showText
                />
              </Form.Item>
              <IoTrashOutline
                className="color-picker-restore"
                onClick={restorePrimaryColorText}
              />
              <div className="input-help">{t('Lorem ipsum')}</div>
            </div>

            <label className="personnalisation-form-label" htmlFor="secondaryColor">
              {t('Secondary color', { ns: 'personalization' })}
            </label>
            <div className="input-with-help">
              <Form.Item name="secondaryColor" label={false} rules={[{ required: true }]}>
                <ColorPicker
                  onChange={(value, hex) => {
                    form.setFieldsValue({ secondaryColor: hex })
                  }}
                  onChangeComplete={(color) => {
                    setSecondaryColor(color.toHexString())
                  }}
                  showText
                />
              </Form.Item>
              <IoTrashOutline
                className="color-picker-restore"
                onClick={restoreSecondaryColor}
              />
              <div className="input-help">{t('Lorem ipsum')}</div>
            </div>

            <label className="personnalisation-form-label" htmlFor="secondaryColorText">
              {t('Secondary color text', { ns: 'personalization' })}
            </label>
            <div className="input-with-help">
              <Form.Item
                name="secondaryColorText"
                label={false}
                rules={[{ required: true }]}
              >
                <ColorPicker
                  onChange={(value, hex) => {
                    form.setFieldsValue({ secondaryColorText: hex })
                  }}
                  onChangeComplete={(color) => {
                    setSecondaryColorText(color.toHexString())
                  }}
                  showText
                />
              </Form.Item>
              <IoTrashOutline
                className="color-picker-restore"
                onClick={restoreSecondaryColorText}
              />
              <div className="input-help">{t('Lorem ipsum')}</div>
            </div>

            <label className="personnalisation-form-label" htmlFor="tertiaryColor">
              {t('Tertiary color', { ns: 'personalization' })}
            </label>
            <div className="input-with-help">
              <Form.Item name="tertiaryColor" label={false} rules={[{ required: true }]}>
                <ColorPicker
                  onChange={(value, hex) => {
                    form.setFieldsValue({ tertiaryColor: hex })
                  }}
                  onChangeComplete={(color) => {
                    setTertiaryColor(color.toHexString())
                  }}
                  showText
                />
              </Form.Item>
              <IoTrashOutline
                className="color-picker-restore"
                onClick={restoreTertiaryColor}
              />
              <div className="input-help">{t('Lorem ipsum')}</div>
            </div>

            <label className="personnalisation-form-label" htmlFor="tertiaryColorText">
              {t('Tertiary color text', { ns: 'personalization' })}
            </label>
            <div className="input-with-help">
              <Form.Item
                name="tertiaryColorText"
                label={false}
                rules={[{ required: true }]}
              >
                <ColorPicker
                  onChange={(value, hex) => {
                    form.setFieldsValue({ tertiaryColorText: hex })
                  }}
                  onChangeComplete={(color) => {
                    setTertiaryColorText(color.toHexString())
                  }}
                  showText
                />
              </Form.Item>
              <IoTrashOutline
                className="color-picker-restore"
                onClick={restoreTertiaryColorText}
              />
              <div className="input-help">{t('Lorem ipsum')}</div>
            </div>

            <Form.Item>
              <Row>
                <Col span={12}>
                  <Button onClick={resetToDefault}>{t('Reset', { ns: 'common' })}</Button>
                </Col>
                <Col span={12}>
                  <div className="submit-button-container">
                    <Button type="default" onClick={() => navigate('/upcoming-meetings')}>
                      {t('Back', { ns: 'common' })}
                    </Button>
                    <Button type="primary" htmlType="submit">
                      {t('Submit', { ns: 'common' })}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        )}
      </Card>
    </Draggable>
  )
})
DesignerTools.displayName = 'DesignerTools'
export default DesignerTools
